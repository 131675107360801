<template>
  <div class="school-room-manage-container">
    <!-- 顶部按钮区域 -->
    <el-row>
      <el-button>导入</el-button>
      <el-button type="primary" @click="handleAddSchoolRoomBtnClick"
        >新增功能室</el-button
      >
    </el-row>
    <!-- 查询表单区域 -->
    <el-row style="margin-top: 20px">
      <el-form inline :model="schoolRoomCondition">
        <el-form-item
          prop="sysOrgSchoolId"
          label="所属学校"
          class="formItemBoxStyle"
        >
          <el-select
            v-model="schoolRoomCondition.sysOrgSchoolId"
            @change="handleQuerySchoolRoomBtnClick"
          >
            <el-option label="全部学校" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.sysOrgSchoolId"
              :label="item.name"
              :value="item.sysOrgSchoolId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="sysOrgSchoolRoomCodeName"
          label="功能室编号/名称"
          class="formItemBoxStyle"
        >
          <el-input
            v-model="schoolRoomCondition.sysOrgSchoolRoomCodeName"
            placeholder="请输入"
            @blur="handleQuerySchoolRoomBtnClick"
          />
        </el-form-item>
        <el-form-item
          prop="sysSubjectDicId"
          label="所属科目"
          class="formItemBoxStyle"
        >
          <el-select
            v-model="schoolRoomCondition.sysSubjectDicId"
            @change="handleQuerySchoolRoomBtnClick"
          >
            <el-option label="全部科目" value=""></el-option>
            <el-option
              v-for="item in subjectList"
              :key="item.sysDicId"
              :label="item.sysDicName"
              :value="item.sysDicId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="状态" class="formItemBoxStyle">
          <el-select
            v-model="schoolRoomCondition.status"
            @change="handleQuerySchoolRoomBtnClick"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="handleResetSchoolRoomBtnClick"
            style="margin-left: 30px"
            >重置</el-button
          >
          <el-button type="primary" @click="handleQuerySchoolRoomBtnClick"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-table
      :data="schoolRoomTableData"
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        prop="sysOrgSchoolId"
        label="所属学校"
        center
        :formatter="customFormatter"
      ></el-table-column>
      <el-table-column prop="name" label="功能室名称" center></el-table-column>
      <el-table-column prop="code" label="功能室编号" center></el-table-column>
      <el-table-column
        prop="sysRoomTypeDicName"
        label="功能室类型"
        center
      ></el-table-column>
      <el-table-column
        prop="sysSubjectDicId"
        label="所属科目"
        center
        :formatter="customFormatter"
      ></el-table-column>
      <el-table-column prop="administratorUserList" label="管理员" center>
        <template slot-scope="scope">
          {{
            scope.row.administratorUserAdminDTOList && scope.row.administratorUserAdminDTOList[0] ? scope.row.administratorUserAdminDTOList[0].nickName : "/"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="inspectorUserList" label="巡查员" center>
        <template slot-scope="scope">
          {{
            scope.row.administratorUserAdminDTOList && scope.row.inspectorUserAdminDTOList[0] ? scope.row.inspectorUserAdminDTOList[0].nickName : "/"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="equipmentManagement" label="设备管理" center>
        <template slot-scope="scoped">
          <span
            @click="
              handleEquipManagementBtnClick(scoped.row.sysOrgSchoolRoomId)
            "
            style="color: #00f; text-decoration: underline; cursor: pointer"
            >设备管理</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" center width="120px">
        <template slot-scope="scoped">
          {{ scoped.row.status === 1 ? "启用" : "停用" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" center>
        <template slot-scope="scoped">
          <!-- 停用状态 -->
          <div
            v-if="scoped.row.status !== 1"
            style="display: flex; margin-left: 25px"
          >
            <div
              style="
                margin-right: 10px;
                cursor: pointer;
                color: green;
                text-decoration: underline;
              "
              @click="handleEditSchoolRoomBtnClick(scoped.row)"
            >
              编辑
            </div>
            <div
              style="
                margin-right: 10px;
                cursor: pointer;
                color: rgb(64, 158, 255);
                text-decoration: underline;
              "
              @click="handleOpenChangeSchoolRoomDialog('启用', scoped.row)"
            >
              启用
            </div>
            <div
              @click="handleOpenChangeSchoolRoomDialog('删除', scoped.row)"
              style="cursor: pointer; color: red; text-decoration: underline"
            >
              删除
            </div>
          </div>
          <!-- 启动状态 -->
          <div v-else style="display: flex; margin-left: 44px">
            <div
              style="
                margin-left: 18px;
                cursor: pointer;
                color: red;
                text-decoration: underline;
              "
              @click="handleOpenChangeSchoolRoomDialog('停用', scoped.row)"
            >
              停用
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeSchoolRoomPageInfo"
        @current-change="handleCurrentChangeSchoolRoomPageInfo"
        :current-page="schoolRoomPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="schoolRoomPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="schoolRoomPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 新增或编辑功能室弹框 -->
    <el-dialog
      :title="addOrEditSchoolRoom === 1 ? '新增功能室' : '编辑功能室'"
      :visible.sync="schoolRoomDialogVisible"
      :before-close="handleCancelSchoolRoomBtnClick"
      top="3%"
      width="800px"
    >
      <div style="width: 550px; margin: 0 auto">
        <el-form
          class="schoolRoomFormData"
          :model="schoolRoomFormData"
          ref="schoolRoomFormData"
          :rules="rules"
        >
          <el-form-item
            prop="sysOrgSchoolId"
            label="所属学校："
            style="margin-left: 15px"
          >
            <el-select
              v-model="schoolRoomFormData.sysOrgSchoolId"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="subject"
            label="所属科目："
            style="margin-left: 15px"
          >
            <el-select
              v-model="schoolRoomFormData.subject"
              placeholder="请选择"
              @change="changeSubjectSelect"
              style="width: 400px"
            >
              <el-option
                v-for="item in subjectList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="schoolRoomName" label="功能室名称：">
            <el-input
              v-model="schoolRoomFormData.schoolRoomName"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="schoolRoomCode" label="功能室编号：">
            <el-input
              v-model="schoolRoomFormData.schoolRoomCode"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="schoolRoomType" label="功能室类型：">
            <el-select
              v-model="schoolRoomFormData.schoolRoomType"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="item in schoolRoomTypeList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="mainMediaUrl"
            label="主媒体流："
            style="margin-left: 15px"
          >
            <el-input
              v-model="schoolRoomFormData.mainMediaUrl"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            />
          </el-form-item>
          <el-form-item
            prop="assistMediaUrl"
            label="辅媒体流："
            style="margin-left: 15px"
          >
            <el-input
              v-model="schoolRoomFormData.assistMediaUrl"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            />
          </el-form-item>
          <el-form-item style="margin-left: -42px">
            <span class="redStar">是否关联配备标准：</span>
            <el-switch
              style="margin-left: 10px"
              v-model="relativeEquipStandard"
              active-color="#13ce66"
              inactive-color="#eee"
              @change="checkSubjectExist"
            >
            </el-switch>
            <el-select
              v-if="relativeEquipStandard"
              v-model="selectEquipStandard"
              multiple
              placeholder="请选择"
              style="width: 350px; margin-left: 10px"
            >
              <el-option
                v-for="item in equipStandardList"
                :key="item.equipStandardId"
                :label="item.equipStandardName"
                :value="item.equipStandardId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="schoolRoomManager"
            label="管理员："
            style="margin-left: 27px"
          >
            <el-select
              v-model="schoolRoomFormData.schoolRoomManager"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="item in adminList"
                :key="item.userId"
                :value="item.userId"
                :label="item.nickName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="schoolRoomPatrolMan">
            <span style="margin-left: 38px">巡查员：</span>
            <el-select
              v-model="schoolRoomFormData.schoolRoomPatrolMan"
              placeholder="请选择"
              style="width: 400px; margin-left: 12px"
            >
              <el-option
                v-for="item in adminList"
                :key="item.userId"
                :value="item.userId"
                :label="item.nickName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="address">
            <span style="margin-left: 24px">所在地址：</span>
            <el-input
              v-model="schoolRoomFormData.address"
              placeholder="请输入"
              style="width: 400px; margin-left: 12px"
            >
            </el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button
              type="primary"
              v-throttle
              @click="handleConfirmSchoolRoomBtnClick"
              >确认</el-button
            >
            <el-button v-throttle @click="handleCancelSchoolRoomBtnClick"
              >取消</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 设备管理弹框 -->
    <el-dialog
      title="设备管理"
      :visible.sync="equipManagementDialogVisible"
      width="1000px"
      top="5%"
    >
      <div
        style="
          margin: 0 20px;
          margin-bottom: 20px;
          position: relative;
          text-align: right;
        "
      >
        <div style="position: absolute; left: 0; bottom: 0">设备清单:</div>
        <div>
          <el-button type="primary">导入设备清单</el-button>
          <el-button type="primary" @click="handleAddEquipManagementBtnClick"
            >添加设备</el-button
          >
        </div>
        <el-row style="margin: 20px 0">
          <el-table
            :data="equipManagementTableData"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            height="500"
          >
            <el-table-column width="50" type="index"></el-table-column>
            <el-table-column label="设备名称" prop="name"></el-table-column>
            <el-table-column label="设备编号" prop="code"></el-table-column>
            <el-table-column
              label="设备描述"
              prop="description"
            ></el-table-column>
            <el-table-column label="设备资源" prop="resource"></el-table-column>
            <el-table-column
              label="媒体流地址"
              prop="mediaUrl"
            ></el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <span
                  style="margin-right: 10px; cursor: pointer; color: green"
                  @click="handleEditDeviceBtnClick(scope.row)"
                >
                  编辑
                </span>
                <span
                  @click="handleDeleteDeviceBtnClick(scope.row)"
                  style="cursor: pointer; color: red"
                >
                  删除
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
    </el-dialog>
    <!-- 新增编辑设备弹框 -->
    <el-dialog
      :title="addOrEditService == 1 ? '新增设备' : '编辑设备'"
      width="700px"
      :visible.sync="handleServiceDialogVisible"
      top="12%"
    >
      <div style="width: 600px; margin: 0 auto">
        <el-form ref="serviceForm" :model="serviceForm" :rules="serviceRules">
          <el-form-item label="设备类型：" prop="sysRoomDeviceTypeDicId">
            <el-select
              v-model="serviceForm.sysRoomDeviceTypeDicId"
              placeholder="请输入"
              style="width: 450px"
            >
              <el-option
                v-for="item in serviceTypeList"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称：" prop="name">
            <el-input
              v-model="serviceForm.name"
              placeholder="请输入"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="20"
            />
          </el-form-item>
          <el-form-item label="设备编号：" prop="code">
            <el-input
              v-model="serviceForm.code"
              placeholder="请输入"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="20"
            />
          </el-form-item>
          <el-form-item
            label="设备描述："
            prop="description"
            style="margin-left: 10px"
          >
            <el-input
              v-model="serviceForm.description"
              placeholder="请输入"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="20"
            />
          </el-form-item>
          <el-form-item
            label="设备资源："
            prop="resource"
            style="margin-left: 10px"
          >
            <el-input
              v-model="serviceForm.resource"
              placeholder="请输入"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="20"
            />
          </el-form-item>
          <el-form-item
            label="媒体流地址："
            prop="mediaUrl"
            style="margin-left: -4px"
          >
            <el-input
              v-model="serviceForm.mediaUrl"
              placeholder="请输入"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="100"
            />
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="handleConfirmDeviceBtnClick"
          >确认</el-button
        >
        <el-button
          @click="
            () => {
              this.handleServiceDialogVisible = false;
            }
          "
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDicFormInfoApi } from "@/api/standard/dictionaryForm.js";
import {
  addSchoolRoomApi,
  deleteSchoolRoomApi,
  getSchoolRoomDetailApi,
  getSchoolRoomListApi,
  updateSchoolRoomApi,
  getDeviceListApi,
  addDeviceApi,
  deleteDeviceApi,
  updateDeviceApi,
  getAdminListApi,
} from "@/api/mechanism/schoolRoomManage.js";
import { getAllSchoolWithoutPageApi } from "@/api/mechanism/examSchool.js";
import { isEmpty, formatDate } from "@/utils/util";
import { getAllEquipStandardListApi } from "@/api/standard/equipManagement.js";
export default {
  name: "SchoolRoomManage",
  data() {
    const checkSchoolRoomCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z-_]/g;
      if (reg.test(this.schoolRoomFormData.schoolRoomCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    const checkServiceCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z-_]/g;
      if (reg.test(this.serviceForm.code)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      addOrEditSchoolRoom: 1, // 1 新增 0 编辑
      schoolRoomCondition: {
        sysOrgSchoolId: "",
        sysOrgSchoolRoomCodeName: "",
        sysSubjectDicId: "",
        status: "",
      },
      schoolRoomTableData: [],
      schoolRoomPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      subjectList: [],
      schoolList: [],
      schoolRoomTypeList: [],
      schoolRoomDialogVisible: false,
      schoolRoomFormData: {
        sysOrgSchoolId: "",
        subject: "",
        schoolRoomName: "",
        schoolRoomCode: "",
        schoolRoomType: "",
        schoolRoomManager: "",
        schoolRoomPatrolMan: "",
        address: "",
        equipStandard: "",
        mainMediaUrl: "",
        assistMediaUrl: "",
      },
      rules: {
        sysOrgSchoolId: [
          { required: true, message: "请选择所属学校", trigger: "change" },
        ],
        subject: [
          { required: true, message: "请选择所属科目", trigger: "change" },
        ],
        schoolRoomName: [
          { required: true, message: "请输入功能室名称", trigger: "blur" },
        ],
        assistMediaUrl: [
          { required: true, message: "请输入辅媒体流", trigger: "blur" },
        ],
        mainMediaUrl: [
          { required: true, message: "请输入主媒体流", trigger: "blur" },
        ],
        schoolRoomCode: [
          { required: true, message: "请输入功能室编号", trigger: "blur" },
          // { validator: checkSchoolRoomCode, trigger: "blur" },
        ],
        schoolRoomType: [
          { required: true, message: "请选择功能室类型", trigger: "change" },
        ],
        schoolRoomManager: [
          { required: true, message: "请选择管理员", trigger: "change" },
        ],
      },
      equipManagementDialogVisible: false,
      equipManagementTableData: [],
      errorList: new Set(),
      sysOrgSchoolRoomId: "",
      relativeEquipStandard: false,
      selectEquipStandard: [], // 用于下拉多选
      tempRowData: "",
      equipStandardList: [],
      equipStandardCondition: {
        periodDicId: 0,
        subjectDicId: 0,
      },

      // 设备类型字典表
      serviceTypeList: [],

      // 新增或编辑设备
      addOrEditService: 1, // 1 新增 0 编辑

      handleServiceDialogVisible: false,
      serviceForm: {
        name: "",
        code: "",
        description: "",
        mediaUrl: "",
        resource: "",
        sysRoomDeviceTypeDicId: "",
      },
      serviceRules: {
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        sysRoomDeviceTypeDicId: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        code: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
          { validator: checkServiceCode, trigger: "blur" },
        ],
      },

      adminList: [],
    };
  },
  created() {
    this.fetchSchoolRoomInfo();
    this.fetchSubjectInfo();
    this.fetchSchoolRoomTypeInfo();
    this.fetchSchoolInfo();
    this.fetchDeviceDicData();
    this.fetchAdminList();
  },
  methods: {
    // 获取功能室数据
    fetchSchoolRoomInfo(data = this.schoolRoomCondition) {
      getSchoolRoomListApi(
        this.schoolRoomPageInfo.pageIndex,
        this.schoolRoomPageInfo.pageSize,
        data
      ).then((res) => {
        if (res.success) {
          this.schoolRoomTableData = res.data;
          this.schoolRoomPageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取学校信息
    fetchSchoolInfo() {
      getAllSchoolWithoutPageApi().then((res) => {
        if (res.success) {
          // console.log(res)
          this.schoolList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取科目信息
    fetchSubjectInfo() {
      getDicFormInfoApi(4).then((res) => {
        if (res.success) {
          // console.log(res);
          this.subjectList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取配备标准
    fetchEquipStandardData() {
      let params = {
        ...this.equipStandardCondition,
        sysOrgSchoolId: this.schoolRoomFormData.sysOrgSchoolId,
      };
      getAllEquipStandardListApi(params).then((res) => {
        if (res.success) {
          this.equipStandardList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取功能室设备类型字典表数据
    fetchDeviceDicData() {
      getDicFormInfoApi(8).then((res) => {
        if (res.success) {
          this.serviceTypeList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取功能室类型信息
    fetchSchoolRoomTypeInfo() {
      getDicFormInfoApi(7).then((res) => {
        if (res.success) {
          this.schoolRoomTypeList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取全部管理员
    fetchAdminList() {
      getAdminListApi({}).then((res) => {
        // console.log(res)
        if (res.success) {
          this.adminList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 新增功能室按钮
    handleAddSchoolRoomBtnClick() {
      this.addOrEditSchoolRoom = 1;
      this.schoolRoomFormData = {
        sysOrgSchoolId: "",
        subject: "",
        schoolRoomName: "",
        schoolRoomCode: "",
        schoolRoomType: "",
        schoolRoomManager: "",
        schoolRoomPatrolMan: "",
        address: "",
        mainMediaUrl: "",
        assistMediaUrl: "",
      };
      this.schoolRoomDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.schoolRoomFormData.clearValidate();
      });
    },
    // 编辑功能室按钮
    handleEditSchoolRoomBtnClick(row) {
      this.clearData();
      this.tempRowData = row;
      getSchoolRoomDetailApi(row.sysOrgSchoolRoomId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.addOrEditSchoolRoom = 0;
          this.schoolRoomFormData = {
            sysOrgSchoolId: data.sysOrgSchoolId,
            subject: data.sysSubjectDicId,
            schoolRoomName: data.name,
            schoolRoomCode: data.code,
            schoolRoomType: data.sysRoomTypeDicId,
            schoolRoomManager: data.administratorUserList[0],
            schoolRoomPatrolMan: data.inspectorUserList[0],
            address: data.address,

            mainMediaUrl: data.mainMediaUrl,
            assistMediaUrl: data.assistMediaUrl,
          };
          this.sysOrgSchoolRoomId = data.sysOrgSchoolRoomId;
          this.relativeEquipStandard =
            data.hasEquipStandard == 1 ? true : false;
          this.schoolRoomDialogVisible = true;
          if (this.relativeEquipStandard == 1) {
            this.equipStandardCondition.subjectDicId =
              this.schoolRoomFormData.subject;
            this.fetchEquipStandardData(this.equipStandardCondition);
            //获取到的配备标准ID
            this.selectEquipStandard = data.sysExperimentEquipStandardIdList;
          }
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认添加或修改功能室
    handleConfirmSchoolRoomBtnClick() {
      this.$refs.schoolRoomFormData.validate((valid) => {
        if (valid) {
          if (
            this.relativeEquipStandard &&
            this.selectEquipStandard.length === 0
          ) {
            this.$message.error("请添加配备标准");
            return;
          }
          if (this.addOrEditSchoolRoom === 1) {
            // console.log(this.schoolRoomFormData);
            // 新增操作
            let addSchoolRoomParams = {
              name: this.schoolRoomFormData.schoolRoomName,
              code: this.schoolRoomFormData.schoolRoomCode,
              sysSubjectDicId: this.schoolRoomFormData.subject
                ? this.schoolRoomFormData.subject
                : 0,
              enable: 1,
              address: this.schoolRoomFormData.address,
              administratorUserList: this.schoolRoomFormData.schoolRoomManager
                ? [this.schoolRoomFormData.schoolRoomManager]
                : [],
              sysRoomTypeDicId: this.schoolRoomFormData.schoolRoomType,
              inspectorUserList: this.schoolRoomFormData.schoolRoomPatrolMan
                ? [this.schoolRoomFormData.schoolRoomPatrolMan]
                : [],
              sysOrgSchoolId: this.schoolRoomFormData.sysOrgSchoolId,
              type: this.schoolRoomFormData.schoolRoomType
                ? this.schoolRoomFormData.schoolRoomType
                : 1,
              statusOccupied: 0,
              hasEquipStandard: this.relativeEquipStandard ? 1 : 0,
              sysExperimentEquipStandardIdList: this.selectEquipStandard,

              mainMediaUrl: this.schoolRoomFormData.mainMediaUrl,
              assistMediaUrl: this.schoolRoomFormData.assistMediaUrl,
            };
            // console.log(addSchoolRoomParams)
            addSchoolRoomApi(addSchoolRoomParams).then((res) => {
              if (res.success) {
                this.$message.success("新增成功!");
                this.fetchSchoolRoomInfo();
                this.schoolRoomDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑操作
            let editSchoolRoomParams = {
              ...this.tempRowData,
              name: this.schoolRoomFormData.schoolRoomName,
              code: this.schoolRoomFormData.schoolRoomCode,
              sysSubjectDicId: this.schoolRoomFormData.subject
                ? this.schoolRoomFormData.subject
                : 0,
              enable: 1,
              address: this.schoolRoomFormData.address,
              administratorUserList: this.schoolRoomFormData.schoolRoomManager
                ? [this.schoolRoomFormData.schoolRoomManager]
                : [],
              inspectorUserList: this.schoolRoomFormData.schoolRoomPatrolMan
                ? [this.schoolRoomFormData.schoolRoomPatrolMan]
                : [],
              sysOrgSchoolId: this.schoolRoomFormData.sysOrgSchoolId,
              sysRoomTypeDicId: this.schoolRoomFormData.schoolRoomType
                ? this.schoolRoomFormData.schoolRoomType
                : "",
              statusOccupied: 0,
              sysOrgSchoolRoomId: this.sysOrgSchoolRoomId,
              status: 2,
              hasEquipStandard: this.relativeEquipStandard ? 1 : 0,
              sysExperimentEquipStandardIdList: this.selectEquipStandard,

              mainMediaUrl: this.schoolRoomFormData.mainMediaUrl,
              assistMediaUrl: this.schoolRoomFormData.assistMediaUrl,
            };
            updateSchoolRoomApi(editSchoolRoomParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功!");
                this.fetchSchoolRoomInfo();
                this.schoolRoomDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 取消添加或修改功能室
    handleCancelSchoolRoomBtnClick() {
      this.schoolRoomDialogVisible = false;
      this.clearData();
    },
    clearData() {
      this.equipStandardList = [];
      this.selectEquipStandard = [];
      this.equipStandardCondition.subjectDicId = 0;
      this.relativeEquipStandard = false;
    },
    // 表格内设备管理按钮点击事件
    handleEquipManagementBtnClick(
      sysOrgSchoolRoomId = this.sysOrgSchoolRoomId
    ) {
      let params = {
        sysOrgSchoolRoomId,
        // sysRoomDeviceTypeDicId: 0,
      };
      getDeviceListApi(params).then((res) => {
        if (res.success) {
          this.sysOrgSchoolRoomId = sysOrgSchoolRoomId;
          this.equipManagementTableData = res.data;
          this.equipManagementDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加设备按钮
    handleAddEquipManagementBtnClick() {
      this.serviceForm = {
        name: "",
        code: "",
        description: "",
        mediaUrl: "",
        resource: "",
      };
      this.addOrEditService = 1;
      this.handleServiceDialogVisible = true;
    },
    // 编辑设备按钮
    handleEditDeviceBtnClick(row) {
      console.log(row.sysOrgSchoolRoomDeviceId);
      this.serviceForm = {
        name: row.name,
        code: row.code,
        description: row.description,
        mediaUrl: row.mediaUrl,
        resource: row.resource,
        sysRoomDeviceTypeDicId: row.sysRoomDeviceTypeDic.sysDicId,
      };
      this.addOrEditService = 0;
      this.sysOrgSchoolRoomDeviceId = row.sysOrgSchoolRoomDeviceId;
      this.handleServiceDialogVisible = true;
    },
    // 删除设备按钮
    handleDeleteDeviceBtnClick(row) {
      this.$confirm(`确定删除该设备吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDeviceApi([row.sysOrgSchoolRoomDeviceId]).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.handleEquipManagementBtnClick();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 确认设备操作按钮
    handleConfirmDeviceBtnClick() {
      this.$refs.serviceForm.validate((valid) => {
        if (valid) {
          if (this.addOrEditService == 1) {
            // 新增
            let params = {
              code: this.serviceForm.code,
              name: this.serviceForm.name,
              description: this.serviceForm.description,
              mediaUrl: this.serviceForm.mediaUrl,
              resource: this.serviceForm.resource,
              sysOrgSchoolRoomId: this.sysOrgSchoolRoomId,
              sysRoomDeviceTypeDicId: this.serviceForm.sysRoomDeviceTypeDicId,
            };
            addDeviceApi(params).then((res) => {
              if (res.success) {
                this.$message.success("设备添加成功");
                this.handleEquipManagementBtnClick();
                this.handleServiceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑
            let params = {
              code: this.serviceForm.code,
              name: this.serviceForm.name,
              sysOrgSchoolRoomDeviceId: this.sysOrgSchoolRoomDeviceId,
              sysOrgSchoolRoomId: this.sysOrgSchoolRoomId,
              sysRoomDeviceTypeDicId: this.serviceForm.sysRoomDeviceTypeDicId,
              description: this.serviceForm.description,
              mediaUrl: this.serviceForm.mediaUrl,
              resource: this.serviceForm.resource,
            };
            // console.log(params.sysOrgSchoolRoomDeviceId)
            updateDeviceApi(params).then((res) => {
              if (res.success) {
                this.$message.success("设备更新成功");
                this.handleEquipManagementBtnClick();
                this.handleServiceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 表格内删除设备按钮
    handleDeleteEquipManagementBtnClick(row) {
      for (let i = 0; i < this.equipManagementTableData.length; i++) {
        if (
          this.equipManagementTableData[i].EquipManagementId ===
          row.EquipManagementId
        ) {
          this.equipManagementTableData.splice(i, 1);
          break;
        }
      }
    },
    // 设备管理表单校验方法
    testEquipManagementCodeInputFunc() {
      for (let i = 0; i < this.equipManagementTableData.length; i++) {
        for (let j = 0; j < this.equipManagementTableData.length; j++) {
          if (i !== j) {
            if (
              this.equipManagementTableData[i].EquipManagementId ==
              this.equipManagementTableData[j].EquipManagementId
            ) {
              this.equipManagementTableData[i].isCodeInputError = true;
              this.equipManagementTableData[j].isCodeInputError = true;
              this.errorList.add(2);
              return false;
            }
          }
        }
      }
      return true;
    },
    // 启用、停用、删除功能室按钮
    handleOpenChangeSchoolRoomDialog(val, row) {
      this.$confirm(`确定${val}该功能室吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val === "删除") {
            // 进行删除操作
            deleteSchoolRoomApi([row.sysOrgSchoolRoomId]).then((res) => {
              if (res.success) {
                this.fetchSchoolRoomInfo();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              ...row,
              status: val === "启用" ? 1 : 0,
            };
            console.log(params);
            // 进行编辑操作
            updateSchoolRoomApi(params).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
                this.fetchSchoolRoomInfo();
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 重置功能室表格
    handleResetSchoolRoomBtnClick() {
      this.schoolRoomCondition = {
        sysOrgSchoolId: "",
        sysOrgSchoolRoomCodeName: "",
        sysSubjectDicId: "",
        status: "",
      };
      this.schoolRoomPageInfo.pageIndex = 1;
      this.schoolRoomPageInfo.pageSize = 10;
      this.fetchSchoolRoomInfo();
    },
    // 查询功能室表格
    handleQuerySchoolRoomBtnClick() {
      this.schoolRoomPageInfo.pageIndex = 1;
      this.schoolRoomPageInfo.pageSize = 10;
      this.fetchSchoolRoomInfo();
    },
    // 修改当前页事件
    handleCurrentChangeSchoolRoomPageInfo(val) {
      this.schoolRoomPageInfo.pageIndex = val;
      this.fetchSchoolRoomInfo();
    },
    // 修改当前页容量事件
    handleSizeChangeSchoolRoomPageInfo(val) {
      this.schoolRoomPageInfo.pageIndex = 1;
      this.schoolRoomPageInfo.pageSize = val;
      this.fetchSchoolRoomInfo();
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "sysOrgSchoolId":
          for (let i = 0; i < this.schoolList.length; i++) {
            if (row.sysOrgSchoolId == this.schoolList[i].sysOrgSchoolId) {
              return this.schoolList[i].name;
            }
          }
        case "sysSubjectDicId":
          for (let i = 0; i < this.subjectList.length; i++) {
            if (row.sysSubjectDicId == this.subjectList[i].sysDicId) {
              return this.subjectList[i].sysDicName;
            }
          }
      }
    },
    //开启关联配备标准，检查是否选择科目
    checkSubjectExist(newvalue) {
      if (this.schoolRoomFormData.sysOrgSchoolId == "") {
        this.$message.error("请先选择所属学校!");
        this.relativeEquipStandard = false;
      } else if (this.schoolRoomFormData.subject == "") {
        this.$message.error("请先选择所属科目!");
        this.relativeEquipStandard = false;
      } else if (this.schoolRoomFormData.subject != "" && newvalue) {
        this.equipStandardCondition.subjectDicId =
          this.schoolRoomFormData.subject;
        this.fetchEquipStandardData(this.equipStandardCondition);
      } else if (newvalue == false) {
        this.equipStandardList = [];
        this.selectEquipStandard = [];
      }
    },
    //科目选择变化,配备标准为开启，改变选项
    changeSubjectSelect() {
      if (this.relativeEquipStandard) {
        this.selectEquipStandard = [];
        this.equipStandardCondition.subjectDicId =
          this.schoolRoomFormData.subject;
        this.fetchEquipStandardData(this.equipStandardCondition);
      }
    },

    // 调用该方法把设备请求参数的设备类型参数指正
    // adjustParamsIdFunc() {
    //   // this.serviceTypeList
    //   // this.equipManagementTableData
    //   this.equipManagementTableData.forEach((item1) => {
    //     this.serviceTypeList.forEach((item2) => {
    //       if (item1.EquipManagementName === item2.sysDicName) {
    //         item1.sysRoomDeviceTypeDicId = item2.sysDicId;
    //       }
    //     });
    //   });

    //   return this.equipManagementTableData.map((item) => {
    //     let params = {
    //       code: item.EquipManagementId,
    //       name: item.EquipManagementName,
    //       sysOrgSchoolRoomDeviceId: item.sysOrgSchoolRoomDeviceId
    //         ? item.sysOrgSchoolRoomDeviceId
    //         : 0,
    //       sysOrgSchoolRoomId: this.sysOrgSchoolRoomId,
    //       sysRoomDeviceTypeDicId: item.sysRoomDeviceTypeDicId
    //         ? item.sysRoomDeviceTypeDicId
    //         : 0,
    //       tenantId: 0,
    //     };
    //     if(item.sysOrgSchoolRoomDeviceId){
    //       // 这里进行编辑
    //       return updateDeviceApi(params)
    //     }else {
    //       // 这里进行新增
    //       return addDeviceApi(params)
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 5px;
}
// ::v-deep .schoolRoomFormData {
//   .el-form-item__error {
//     margin-left: 100px;
//   }
// }
// ::v-deep .error-input {
//   .el-input__inner {
//     border-colorgb(53, 7, 7)red;
//   }
// }
</style>
